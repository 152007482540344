<template>
  <div class="coupon-item-dialog">
    <el-dialog
      title="适用范围选择"
      :visible.sync="showDialog"
      top="3%"
      width="50%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <!-- start 自定义内容 -->
      <div class="content-box">
        <el-collapse class="seletct-box" v-model="collapseActives" @change="onChangeCollapse">
          <el-collapse-item
            class="select-item"
            v-for="(item, index) in itemsList"
            :name="index"
            :key="index"
          >
            <template slot="title">
              <div class="title">
                <el-checkbox
                  v-model="item.CheckAll"
                  :true-label="1"
                  :false-label="0"
                  :indeterminate="item.Indeterminate"
                  :disabled="disabled"
                  @change="onChangeCheckAll(item, index, $event)"
                >
                  {{ item.ClassName }}
                </el-checkbox>
              </div>
            </template>

            <div class="col">
              <template v-for="(item2, index2) in item.ItemList">
                <el-checkbox
                  v-model="item2.Checked"
                  :true-label="1"
                  :false-label="0"
                  :disabled="disabled"
                  @change="onChangeCheckbox(item, index, item2, index2, $event)"
                >
                  {{
                    `${item2.ItemNo}_${item2.ItemName || ""}[${item2.Price}]`
                  }}
                </el-checkbox>
              </template>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <!-- end 自定义内容 -->

      <!-- start 脚部 -->
      <span slot="footer" class="dialog-footer">
        <div v-if="!disabled" class="btn-box">
          <el-button size="small" @click="onCloseDialog">取 消</el-button>
          <el-button size="small" type="primary" @click="onSave"
            >保 存</el-button
          >
        </div>
      </span>
      <!-- end 脚部 -->
    </el-dialog>
  </div>
</template>

<script>
import Coupon from "@/api/coupon";

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      itemsList: [], // 项目列表
      copyItemsList: [],
      editList: [],
      activeDuty: [],
      collapseActives: [],  // 折叠面板节点展开下标
      showDialog: false,
    };
  },

  methods: {
    // 保存选中的
    async onSave() {
      let itemsList = this.itemsList;
      let itemsGuids = [];
      await itemsList.forEach((item, index) => {
        let arr = [];
        if (item.ActiveCount > 0) {
          item.ItemList.filter((e) => {
            if (e.Checked) {
              return arr.push(e.ItemGuid);
            }
          });
        }
        // console.log(arr);
        itemsGuids = [...itemsGuids, ...arr];
      });

      await this.$nextTick(() => {
        this.$emit("success", { itemsGuids, itemsList });
        this.onCloseDialog();
        // console.log(items);
      });
    },

    // 初始化折叠节点状态
    async initCollapse(event) {
      let itemsList = JSON.parse(JSON.stringify(event)),
        copyItemsList = JSON.parse(JSON.stringify(event));
      let actives = []

      if (!itemsList.length) {
        return;
      }

      await itemsList.forEach(async (e, i) => {
        let count = 0;
        let indeterminate = false,
          checkAll = 0;

        await e.ItemList.filter(async (item) => {
          if (await item.Checked) {
            return await count++;
          }
          item.Checked = 0;
        });

        await this.$nextTick(() => {
          if (e.ItemList.length == count && e.ItemList.length != 0) {
            checkAll = 1;
          } else if (count) {
            indeterminate = true;
          }
          count != 0 ? actives.push(i) : ''
          e.ActiveCount = count;
          e.CheckAll = checkAll;
          e.Indeterminate = indeterminate;
          copyItemsList[i].Indeterminate = indeterminate;
        });
      });

      await this.$nextTick(() => {
        this.itemsList = itemsList;
        this.copyItemsList = copyItemsList;
        this.collapseActives = actives
        //   this.activeDuty = arr;
        console.log(this.collapseActives);
      });
    },

    // 折叠节点改变事件
    onChangeCollapse(event) {
      //   console.log(event);
      //   let itemsList = this.itemsList;
      //   let copyItemsList = this.copyItemsList;
      //   event.forEach((item) => {
      //     for (let i = 0; i < itemsList.length; i++) {
      //       itemsList[i].isCollapse = true;
      //       copyItemsList[i].isCollapse = true;
      //       if (item != i) {
      //         itemsList[i].isCollapse = false;
      //         copyItemsList[i].isCollapse = false;
      //       }
      //     }
      //   });
      //   console.log(this.itemsList);
    },

    // 多选框点击事件
    async onChangeCheckbox(item1, index1, item2, index2, event) {
      let itemsList = JSON.parse(JSON.stringify(this.itemsList)),
        copyItemsList = JSON.parse(JSON.stringify(this.copyItemsList)),
        itemList = itemsList[index1].ItemList,
        copyItemList = copyItemsList[index1].ItemList;

      let activeCount = itemsList[index1].ActiveCount,
        indeterminate = false,
        checkAll = 0;

      activeCount = event ? activeCount + 1 : activeCount - 1;

      if (activeCount === itemList.length) {
        checkAll = 1;
      } else if (activeCount > 0) {
        indeterminate = true;
      }

      await this.$nextTick(() => {
        itemsList[index1] = {
          ...itemsList[index1],
          ActiveCount: activeCount,
          Indeterminate: indeterminate,
          CheckAll: checkAll,
        };

        copyItemsList[index1] = {
          ...copyItemsList[index1],
          ActiveCount: activeCount,
          Indeterminate: indeterminate,
          CheckAll: checkAll,
        };

        this.itemsList = itemsList;
        this.copyItemsList = copyItemsList;
        console.log(this.itemsList);
      });
    },

    // 全选框点击事件
    onChangeCheckAll(item, index, event) {
      // console.log(event, this.itemsList);
      let itemsList = JSON.parse(JSON.stringify(this.itemsList)),
        copyItemsList = JSON.parse(JSON.stringify(this.copyItemsList));
      let itemList = itemsList[index].ItemList,
        copyItemList = copyItemsList[index].ItemList;
      let activeCount = 0,
        indeterminate = false,
        checkAll = 0;

      event = event ? 1 : 0;

      itemList.forEach((e, i) => {
        e.Checked = event;
        copyItemList[i].Checked = event;
      });

      if (event) {
        activeCount = itemList.length;
        checkAll = 1;
      }
      itemsList[index] = {
        ...itemsList[index],
        ActiveCount: activeCount,
        Indeterminate: indeterminate,
        CheckAll: checkAll,
      };

      copyItemsList[index] = {
        ...copyItemsList[index],
        ActiveCount: activeCount,
        Indeterminate: indeterminate,
        CheckAll: checkAll,
      };

      this.itemsList = itemsList;
      this.copyItemsList = copyItemsList;
      console.log(this.itemsList);
    },

    // 打开对话框
    onShowDialog(itemsList = []) {
      this.initCollapse(JSON.parse(JSON.stringify(itemsList)));
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.itemsList = [];
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  height: 700px;
  overflow-y: auto;
  .seletct-box {
    margin-top: 20px;
    border-top: 0;

    .select-item {
      &:first-child {
        border-top: 1px solid #ebeef5;
      }
      .title {
        margin-top: 20px;
        margin-bottom: 10px;

        .el-checkbox__label {
          color: #000000;
          font-weight: bold;
        }
      }

      .col {
        .flex-row();
        flex-wrap: wrap;
        padding-left: 40px;
        box-sizing: border-box;

        .el-checkbox {
          width: 25%;
          margin: 10px 0;
        }
      }
    }
  }
}
::v-deep .is-disabled {
  span {
    color: #777 !important;
  }
  .el-checkbox__inner::before{
    background-color: #777 !important;
  }
  .el-checkbox__inner, .el-checkbox__inner::after{
    border-color: #777 !important;
  }
}

.scrollbar;
</style>
