<template>
  <div class="coupon-basic-setting">
    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      top="1%"
      width="60%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <!-- start 自定义内容 -->
      <div class="content-box">
        <el-form :model="editCoupon" ref="form" label-width="140px" size="mini">
          <el-row :gutter="rowSpan">
            <el-col :span="colSpan">
              <el-form-item class="no-box" label="编号">
                <el-input
                  v-model="editCoupon.CouponNo"
                  :disabled="autoNo ? true : false"
                ></el-input>
                <el-checkbox
                  v-model="autoNo"
                  :true-label="1"
                  :false-label="0"
                  @change="onAutoCreate"
                  :disabled="couponGuid != ''"
                  >自动生成编号</el-checkbox
                >
              </el-form-item>
            </el-col>

            <el-col :span="colSpan">
              <el-form-item label="名称">
                <el-input
                  v-model="editCoupon.CouponName"
                  :disabled="couponGuid != ''"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="rowSpan">
            <el-col :span="colSpan">
              <el-form-item label="选择类型">
                <el-select
                  v-model="editCoupon.CouponType"
                  placeholder="请选择"
                  clearable
                  :disabled="couponGuid != ''"
                  @change="onChangeType"
                >
                  <el-option
                    v-for="item in couponTypeDefine"
                    :key="item.Value"
                    :label="item.Name"
                    :value="item.Value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="colSpan">
              <el-form-item
                :label="
                  `优惠${
                    editCoupon.CouponType == 2
                      ? '折扣'
                      : editCoupon.CouponType == 3
                      ? '次数'
                      : '金额'
                  }`
                "
              >
                <el-input
                  v-model="editCoupon.Preferential"
                  :disabled="editCoupon.CouponType == 3 || couponGuid != ''"
                >
                  <template slot="append">{{
                    editCoupon.CouponType == 2
                      ? "%"
                      : editCoupon.CouponType == 3
                      ? "次"
                      : "元"
                  }}</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="rowSpan">
            <el-col :span="colSpan">
              <el-form-item label="微信端限领张数">
                <el-input
                  v-model="editCoupon.WXCount"
                  :disabled="couponGuid != ''"
                >
                  <template slot="append">张</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="colSpan">
              <el-form-item class="valid-date-box" label="有效期">
                <el-input
                  v-model="editCoupon.ValidDate"
                  :disabled="couponGuid != ''"
                >
                  <template slot="append">天</template>
                </el-input>
                <span class="tips">（不填则为无期限）</span>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="rowSpan">
            <el-col :span="colSpan">
              <el-form-item class="cost-box" label="使用成本">
                <el-input
                  v-model="editCoupon.Cost"
                  :disabled="couponGuid != ''"
                ></el-input>
                <el-select
                  v-model="editCoupon.CostUnit"
                  placeholder="请选择"
                  :disabled="couponGuid != ''"
                >
                  <el-option
                    v-for="item in costUnitDefine"
                    :key="item.Value"
                    :label="item.Name"
                    :value="item.Value"
                  >
                  </el-option>
                </el-select>
                <div class="tips">使用成本:用于业绩计算中覆盖优惠的成本</div>
              </el-form-item>
            </el-col>
            <el-col :span="colSpan">
              <el-form-item label="兑换价格">
                <el-input
                  v-model="editCoupon.Price"
                  :disabled="couponGuid != ''"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="rowSpan">
            <el-col :span="colSpan">
              <el-form-item label="领券自动新建会员">
                <el-radio-group
                  v-model="editCoupon.GenerateMember"
                  :disabled="couponGuid != ''"
                >
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="rowSpan">
            <el-col :span="12">
              <el-form-item label="可用时段">
                <div>
                  <el-checkbox-group
                    v-model="editCoupon.ValidWeekList"
                    :disabled="couponGuid != ''"
                    @change="onChangeWeek"
                  >
                    <el-checkbox
                      v-for="(item, index) in validWeekDefine"
                      :key="index"
                      :label="item"
                    ></el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row v-if="distribCoupon" :gutter="rowSpan">
            <el-col :span="rowSpan">
              <el-form-item class="coupon-box" label="分销优惠券">
                <el-checkbox
                  v-model="editCoupon.IsDistribution"
                  :label="1"
                  :true-label="1"
                  :false-label="0"
                  :checked="editCoupon.IsDistribution ? true : false"
                  :disabled="couponGuid != ''"
                  >设为分销券优惠券</el-checkbox
                >

                <el-radio-group
                  v-if="editCoupon.IsDistribution"
                  v-model="editCoupon.DistributionCoupon"
                  size="small"
                  :disabled="couponGuid != ''"
                >
                  <el-radio :label="0">
                    <template>
                      根据
                      <el-select
                        v-model="editCoupon.DistributionRatio"
                        :disabled="editCoupon.DistributionUnit == 1"
                      >
                        <el-option
                          v-for="item in distribRatioDefine"
                          :key="item.Value"
                          :label="item.Name"
                          :value="item.Value"
                        >
                        </el-option>
                      </el-select>
                      分销佣金
                      <el-input v-model="editCoupon.Distribution">
                        <template
                          v-if="editCoupon.DistributionRatio"
                          slot="append"
                          >%</template
                        >
                      </el-input>
                      <el-select
                        v-if="!editCoupon.DistributionRatio"
                        v-model="editCoupon.DistributionUnit"
                      >
                        <el-option
                          v-for="item in distribUnitDefine"
                          :key="item.Value"
                          :label="item.Name"
                          :value="item.Value"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-radio>
                  <el-radio :label="1">使用分销优惠券</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="rowSpan">
            <el-col :span="rowSpan">
              <el-form-item label="适用范围选择">
                <el-button type="primary" @click="onShowItemSelect"
                  >选择项目</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="rowSpan">
            <el-col :span="rowSpan">
              <el-form-item label="适用范围说明">
                <el-input
                  type="textarea"
                  v-model="editCoupon.CouponNote"
                  :disabled="couponGuid != ''"
                  :rows="3"
                ></el-input>
                <div class="tips">
                  对外说明,可简洁描述优惠券适用范围，如（除特价套餐外全场通用）
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="rowSpan">
            <el-col :span="rowSpan">
              <el-form-item label="使用说明">
                <el-input
                  type="textarea"
                  v-model="editCoupon.UseNote"
                  :disabled="couponGuid != ''"
                  :rows="3"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- end 自定义内容 -->

      <!-- start 脚部 -->
      <span slot="footer" class="dialog-footer">
        <div v-if="!couponGuid" class="btn-box">
          <el-button size="small" @click="onCloseDialog">取 消</el-button>
          <el-button
            size="small"
            type="primary"
            :disabled="submitDisabled"
            @click="saveCoupon"
            >保 存</el-button
          >
        </div>
      </span>
      <!-- end 脚部 -->
    </el-dialog>

    <CouponItemDialog
      ref="couponItemDialog"
      :disabled="couponGuid ? true : false"
      @success="onSaveItemsList"
    ></CouponItemDialog>
  </div>
</template>

<script>
import Coupon from "@/api/coupon";

import CouponItemDialog from "./coupon-item-dialog.vue";

export default {
  components: { CouponItemDialog },

  props: {
    distribCoupon: {
      type: Number,
      default: () => 0,
    },
  },

  data() {
    return {
      rowSpan: 20,
      colSpan: 10,
      autoNo: 1,
      title: "", // 对话框标题
      couponGuid: "", // 优惠券id
      editCoupon: {
        Cost: 0,
        CostUnit: 0,
        CouponName: "",
        CouponNo: "",
        CouponNote: "",
        CouponType: 0,
        Distribution: 0,
        DistributionCoupon: 0,
        DistributionRatio: 0,
        DistributionUnit: 0,
        GenerateMember: 0,
        IsDistribution: 0,
        Preferential: 0,
        Price: 0,
        UseItemList: [],
        UseNote: "",
        ValidDate: "",
        ValidWeekList: [],
        WXCount: 0,
      }, // 初始化数据信息
      itemsSelector: {}, // 项目数据
      costUnitDefine: [], // 成本单位列表
      couponPerfDefine: [], //
      couponTypeDefine: [], // 优惠券类型列表
      distribRatioDefine: [], // 分销单选框内容
      distribUnitDefine: [], // 分销单位列表
      validWeekDefine: [], // 可用时段列表
      weekCheck: [],
      saveItemsList: [],
      showDialog: false,
      submitDisabled: false,
    };
  },

  methods: {
    // 初始化编辑优惠券
    async initSaveCoupon() {
      try {
        let { data } = await Coupon.initSaveCoupon({
          coupon_guid: this.couponGuid,
        });
        data.couponTypeDefine[0].Name = "请选择";
        this.costUnitDefine = data.CostUnitDefine;
        this.couponPerfDefine = data.couponPerfDefine;
        this.couponTypeDefine = data.couponTypeDefine;
        this.distribRatioDefine = data.distribRatioDefine;
        this.distribUnitDefine = data.distribUnitDefine;
        this.itemsSelector = data.itemsSelector;
        this.validWeekDefine = data.validWeekDefine;
        console.log(data.validWeekDefine);
        data.editCoupon.ValidWeekList = JSON.parse(
          JSON.stringify(data.validWeekDefine)
        );
        data.editCoupon.WXCount = 100;
        this.editCoupon = data.editCoupon;
      } catch (err) {
        console.log(err);
      }
    },

    // 保存优惠券
    async saveCoupon() {
      let { editCoupon } = this;
      // console.log(editCoupon);
      if (!editCoupon.CouponNo) {
        return this.$message.info("编号不能为空");
      } else if (!editCoupon.CouponName) {
        return this.$message.info("名称不能为空");
      } else if (!editCoupon.CouponType) {
        return this.$message.info("优惠券类型不能为空");
      } else if (!editCoupon.Preferential) {
        return this.$message.info("优惠金额不能为空");
      } else if (!editCoupon.ValidWeekList.length) {
        return this.$message.info("请选择至少一个可用时间段");
      } else if (!editCoupon.UseItemList.length) {
        return this.$message.info("请选择适用项目");
      }

      editCoupon.Cost = editCoupon.Cost || 0;
      editCoupon.Price = editCoupon.Price || 0;

      this.submitDisabled = true;
      try {
        let { errcode } = await Coupon.saveCoupon(editCoupon);
        if (errcode == 0) {
          this.$message.success("优惠券创建成功");
          this.$emit("success");
          this.resetPageData();
          this.onCloseDialog();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.submitDisabled = false;
      }
    },

    // 优惠券类型改变事件
    onChangeType(event) {
      if (event == 3) {
        this.editCoupon.Preferential = 1;
      }
    },

    // 适用范围项目选择保存回调
    onSaveItemsList(event) {
      let { itemsGuids, itemsList } = event;
      this.editCoupon.UseItemList = itemsGuids;
      this.itemsList = itemsList;
    },

    onChangeWeek(event) {
      console.log(event, this.editCoupon.ValidWeekList);
    },

    // 自动生成编号
    onAutoCreate(event) {
      this.autoNo = event;
      let min = 10000,
        max = 99999;

      if (event) {
        this.editCoupon.CouponNo =
          min + Math.round(Math.random() * (max - min));
      }
    },

    // 重置页面数据
    resetPageData() {
      this.editCoupon = {
        Cost: 0,
        CostUnit: 0,
        CouponName: "",
        CouponNo: "",
        CouponNote: "",
        CouponType: 0,
        Distribution: 0,
        DistributionCoupon: 0,
        DistributionRatio: 0,
        DistributionUnit: 0,
        GenerateMember: 0,
        IsDistribution: 0,
        Preferential: 0,
        Price: 0,
        UseItemList: [],
        UseNote: "",
        ValidDate: "",
        ValidWeekList: [],
        WXCount: 0,
      };
    },

    // 打开使用范围项目对话框
    async onShowItemSelect() {
      let { saveItemsList, editCoupon } = this;
      let itemsList = JSON.parse(JSON.stringify(this.itemsSelector.ClassList));

      if (!saveItemsList.length) {
        await itemsList.forEach((item, index) => {
          item.ItemList.forEach((item2, index2) => {
            item2.Checked = 0;
            editCoupon.UseItemList.forEach((item3, index3) => {
              if (item3 == item2.ItemGuid) {
                item2.Checked = 1;
              }
            });
          });
        });
      } else {
        itemsList = saveItemsList;
      }

      console.log(itemsList);

      await this.$nextTick(() => {
        this.$refs.couponItemDialog.onShowDialog(itemsList);
      });
    },

    // 打开对话框
    async onShowDialog(couponGuid = "") {
      this.couponGuid = couponGuid;
      this.title = `${couponGuid ? "优惠券详情" : "创建优惠券"}`;
      await this.initSaveCoupon();
      await this.onAutoCreate(1);
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.coupon-basic-setting {
  .content-box {
    ::v-deep .el-row {
      .el-col {
        .el-form-item__content {
          > .el-input {
            width: 160px;

            .el-input-group__append {
              padding: 0 10px;
            }
          }

          .tips {
            font-size: 10px;
            color: #999;
          }

          .is-disabled {
            span,
            div,
            input,
            textarea {
              color: #999 !important;
            }

            .el-radio__inner::after {
              background-color: #999 !important;
            }

            .el-checkbox__inner::before {
              background-color: #999 !important;
            }
            .el-checkbox__inner,
            .el-checkbox__inner::after {
              border-color: #999 !important;
            }
          }

          .el-button span {
            color: white !important;
          }
        }

        .no-box {
          .el-checkbox {
            margin-left: 10px;
          }
        }

        .cost-box,
        .valid-date-box {
          .el-input {
            width: 100px;
          }
          .el-select {
            width: 70px;
            margin-left: 10px;

            .el-input {
              width: 100%;
            }
          }
        }

        .coupon-box {
          .el-form-item__content {
            .flex-col;
            align-items: unset;
          }

          .el-radio-group {
            .flex-col;
            align-items: unset;

            label {
              margin: 5px 0;
              .el-input {
                width: 100px !important;
                margin: 0 5px;
                .el-input-group__append {
                  padding: 0 10px;
                }
              }
              .el-select {
                width: 120px;
                margin: 0 5px;
                > .el-input {
                  width: 100% !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
