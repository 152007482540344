<template>
  <div class="empl-send-dialog">
    <el-dialog
      title="员工发放情况"
      :visible.sync="showDialog"
      top="5%"
      width="80%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <!-- start 自定义内容 -->
      <div class="content-box">
        <div class="df-row">
          <div class="df-col">
            <el-date-picker
              v-model="multipleDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="mini"
              value-format="yyyy-MM-dd"
              @change="onChangeDate"
            >
            </el-date-picker>
          </div>

          <div class="df-col">
            <!-- 职位选择器 -->
            <el-select
              v-model="submitData.duty_no"
              placeholder="请选择"
              size="mini"
              @change="onChangeDuty"
            >
              <el-option
                v-for="item in dutyList"
                :key="item.DutyNo"
                :label="item.DutyName"
                :value="item.DutyNo"
              >
              </el-option>
            </el-select>
          </div>

          <div class="df-col">
            <!-- 级别选择器 -->
            <el-select
              v-model="submitData.level_guid"
              placeholder="请选择"
              size="mini"
            >
              <el-option
                v-for="item in levelList"
                :key="item.LevelGuid"
                :label="item.LevelName"
                :value="item.LevelGuid"
              >
              </el-option>
            </el-select>
          </div>

          <div class="df-col">
            <!-- 员工选择器 -->
            <el-select
              v-model="submitData.sender_guid"
              placeholder="请选择"
              size="mini"
            >
              <el-option
                v-for="item in emplList"
                :key="item.EmplGuid"
                :label="item.EmplName"
                :value="item.EmplGuid"
              >
              </el-option>
            </el-select>
          </div>

          <div class="df-col">
            <!-- 查询按钮 -->
            <el-button type="primary" size="mini" @click="getCouponSenderStatis"
              >查询</el-button
            >
          </div>
        </div>

        <el-table
          v-loading="loading"
          :data="dataList"
          max-height="500"
          size="mini"
          border
          stripe
          show-summary
          :summary-method="getSummaries"
        >
          <el-table-column
            prop="EmplName"
            label="员工"
            width=""
          ></el-table-column>
          <el-table-column
            prop="DutyName"
            label="职位"
            width=""
          ></el-table-column>
          <el-table-column
            prop="LevelName"
            label="级别"
            width=""
          ></el-table-column>
          <el-table-column
            prop="GiveOutCount"
            label="券发数量"
            width=""
          ></el-table-column>
          <el-table-column
            prop="ReceiveCount"
            label="券领取量"
            width=""
          ></el-table-column>
          <el-table-column
            prop="NewMemberCount"
            label="建档新客"
            width=""
          ></el-table-column>
          <el-table-column label="券核销率" width="">
            <template slot-scope="scoped"
              >{{ scoped.row.VerifyRatio }}%</template
            >
          </el-table-column>
          <el-table-column prop="Cost" label="成本" width=""></el-table-column>
          <el-table-column
            prop="Perfor"
            label="生产业绩"
            width=""
          ></el-table-column>
          <el-table-column
            prop="ReceivePersonCount"
            label="领取人数"
            width=""
          ></el-table-column>
          <el-table-column
            prop="VerifyPersonCount"
            label="使用人数"
            width=""
          ></el-table-column>
          <el-table-column
            prop="NewMemberPersonCount"
            label="到店新客"
            width=""
          ></el-table-column>
        </el-table>
      </div>
      <!-- end 自定义内容 -->

      <!-- start 脚部 -->
      <!-- <span slot="footer" class="dialog-footer">
          <div class="btn-box">
            <el-button size="small" @click="onCloseDialog">取 消</el-button>
          </div>
        </span> -->
      <!-- end 脚部 -->
    </el-dialog>
  </div>
</template>

<script>
import Coupon from "@/api/coupon";
import Employee from "@/api/employee";

export default {
  data() {
    return {
      submitData: {
        // 提交数据
        begin_date: "",
        end_date: "",
        duty_no: "",
        level_guid: "",
        sender_guid: "",
      },
      multipleDate: [], // 日期选择器数据
      dutyList: [], // 职位列表
      levelList: [], // 级别列表
      copyLevelList: [], // 级别列表（源数据）
      emplList: [], // 员工列表
      dataList: [], // 表格数据
      loading: false, // 表格加载
      showDialog: false, // 对话框显示
      submitDisabled: false, // 提交防抖
      deleteDisabled: false, // 删除防抖
    };
  },

  mounted() {
    this.multipleDate = [
      this.util.getNowMonthToday(),
      this.util.getNowMonthToday(),
    ];
    this.onChangeDate();
  },

  methods: {
    // 初始化职位级别数据
    async getCommonSelector() {
      try {
        let { data } = await Employee.getCommonSelector();
        this.dutyList = [{ DutyName: "不限", DutyNo: "" }, ...data.dutyList];
        this.levelList = [
          { DutyNo: "", LevelGuid: "", LevelName: "不限" },
          ...data.levelList,
        ];
        this.copyLevelList = JSON.parse(JSON.stringify(this.levelList));
        this.emplList = [
          {
            EmplGuid: "",
            EmplName: "不限",
            EmplNo: "",
            LevelGuid: "",
          },
          ...data.emplList,
        ];
        await this.$nextTick(() => {
          this.onChangeDuty();
        });
      } catch (err) {
        console.log(err);
      }
    },

    // 获取员工发送数据列表
    async getCouponSenderStatis() {
      this.loading = true;
      try {
        let { data } = await Coupon.getCouponSenderStatis(this.submitData);
        this.dataList = data;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 自定义合计行函数
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        const values = data.map((item) => Number(item[column.property]));
        if (index === 0) {
          sums[index] = "合计";
          return;
        }else if(index > 0 && index < 3){
            sums[index] = '--'
        }else if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = "--";
        }
      });

      return sums;
    },

    // 职位改变事件
    onChangeDuty() {
      let { submitData } = this;
      let { levelList, dataInfo } = this.util.handleDutyLevel(
        { DutyNo: submitData.duty_no, LevelGuid: submitData.level_guid },
        this.copyLevelList
      );
      this.levelList = levelList;
      this.submitData = {
        ...submitData,
        duty_no: dataInfo.DutyNo,
        level_guid: dataInfo.LevelGuid,
      };
    },

    // 时间更改事件
    onChangeDate() {
      this.submitData.begin_date =
        this.multipleDate.length > 0
          ? this.multipleDate[0]
          : this.util.getNowMonthToday();
      this.submitData.end_date =
        this.multipleDate.length > 0
          ? this.multipleDate[1]
          : this.util.getNowMonthToday();
    },

    // 打开对话框
    async onShowDialog() {
      await this.getCommonSelector();
      await this.getCouponSenderStatis();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.empl-send-dialog {
  .content-box {
    height: 550px;
  }
}
</style>
