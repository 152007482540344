<template>
  <div class="coupon-send-duty-add">
    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      top="14%"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <!-- start 自定义内容 -->
      <div class="content-box">
        <el-form :model="dataInfo" label-width="80px" size="mini">
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="员工职位">
                <!-- 职位选择器 -->
                <el-select
                  v-model="dataInfo.DutyNo"
                  placeholder="请选择"
                  size="mini"
                  @change="onChangeDuty(dataInfo)"
                >
                  <el-option
                    v-for="item in dutyList"
                    :key="item.DutyNo"
                    :label="item.DutyName"
                    :value="item.DutyNo"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="11">
              <!-- 级别选择器 -->
              <el-select
                v-model="dataInfo.LevelGuid"
                placeholder="请选择"
                size="mini"
              >
                <el-option
                  v-for="item in levelList"
                  :key="item.LevelGuid"
                  :label="item.LevelName"
                  :value="item.LevelGuid"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="数量">
                <el-input
                  v-model="dataInfo.Count"
                  size="mini"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- end 自定义内容 -->

      <!-- start 脚部 -->
      <span slot="footer" class="dialog-footer">
        <div class="btn-box">
          <el-button size="small" @click="onCloseDialog">取 消</el-button>
          <el-button
            size="small"
            type="primary"
            :disabled="submitDisabled"
            @click="saveCouponCount"
            >确 定</el-button
          >
        </div>
      </span>
      <!-- end 脚部 -->
    </el-dialog>
  </div>
</template>

<script>
import Coupon from "@/api/coupon.js";
import Employee from "@/api/employee.js";

export default {
  data() {
    return {
      title: "",
      couponGuid: "",
      dataInfo: {
        CouponCountGuid: "",
        CouponGuid: "",
        DutyNo: "",
        LevelGuid: "",
        Count: 0,
      },
      dutyList: [],
      levelList: [],
      copyLevelList: [],
      loading: false,
      showDialog: false,
      submitDisabled: false, // 提交防抖
      deleteDisabled: false, // 删除防抖
    };
  },

  methods: {
    // 保存添加、修改发放设置
    async saveCouponCount() {
      let that = this;
      if (!this.dataInfo.Count) {
        return this.$message.error("数量不能为0");
      }
      this.submitDisabled = true;
      try {
        this.dataInfo.Count = Number(this.dataInfo.Count);
        let { errcode } = await Coupon.saveCouponCount(this.dataInfo);
        if (errcode == 0) {
          if (this.dataInfo.CouponCountGuid) {
            that.$message.success("修改成功");
            that.onCloseDialog();
            return that.$emit("success");
          } else {
            that.$message.success("添加成功");
          }

          that
            .$confirm(`您还要再继续添加吗？`, "提示", {
              confirmButtonText: "继续",
              cancelButtonText: "取消",
              type: "warning",
            })
            .then(async (res) => {
              await that.resetPageData();
              await that.onChangeDuty(this.dataInfo);
            })
            .catch((err) => {
              that.onCloseDialog();
            })
            .finally(() => {
              that.$emit("success");
            });
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.submitDisabled = false;
      }
    },

    // 职位改变事件
    onChangeDuty(event) {
      let { levelList, dataInfo } = this.util.handleDutyLevel(
        this.dataInfo,
        this.copyLevelList
      );
      this.levelList = levelList;
      this.dataInfo = dataInfo;
    },

    resetPageData() {
      this.dataInfo = {
        ...this.dataInfo,
        CouponCountGuid: "",
        DutyNo: "",
        LevelGuid: "",
        Count: 0,
      };
    },

    // 打开对话框
    async onShowDialog(dataInfo = {}, couponGuid, dutyList, levelList) {
      this.title = "添加可发送职位";

      dataInfo = {
        CouponCountGuid: dataInfo.CouponCountGuid,
        CouponGuid: couponGuid,
        DutyNo: dataInfo.DutyNo,
        LevelGuid: dataInfo.LevelGuid,
        Count: dataInfo.Count,
      };
      if (dataInfo.CouponCountGuid) {
        this.title = "修改可发送职位";
      }
      this.dataInfo = JSON.parse(JSON.stringify(dataInfo));
      this.dutyList = JSON.parse(JSON.stringify(dutyList));
      this.levelList = JSON.parse(JSON.stringify(levelList));
      this.copyLevelList = JSON.parse(JSON.stringify(levelList));
      console.log(dataInfo, couponGuid);
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.coupon-send-duty-add {
}
</style>
