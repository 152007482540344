<template>
    <div class="coupon-use-dialog">
      <el-dialog
        title="优惠券使用情况"
        :visible.sync="showDialog"
        top="5%"
        width="80%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        @close="onCloseDialog"
      >
        <!-- start 自定义内容 -->
        <div class="content-box">
          <div class="df-row">
            <div class="df-col">
              <el-date-picker
                v-model="multipleDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="mini"
                value-format="yyyy-MM-dd"
                @change="onChangeDate"
              >
              </el-date-picker>
            </div>
  
            <div class="df-col">
              <!-- 券类型选择器 -->
              <el-select
                v-model="submitData.coupon_type"
                placeholder="请选择"
                size="mini"
                @change=""
              >
                <el-option
                  v-for="item in couponTypeDefine"
                  :key="item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>
            </div>
  
            <div class="df-col">
              <!-- 查询按钮 -->
              <el-button type="primary" size="mini" @click="getCouponSelfStatis"
                >查询</el-button
              >
            </div>
          </div>
  
          <el-table
            v-loading="loading"
            :data="dataList"
            max-height="500"
            size="mini"
            border
            stripe
            show-summary
          :summary-method="getSummaries"
          >
            <el-table-column prop="CouponName" label="优惠券" width=""></el-table-column>
            <el-table-column prop="GiveOutCount" label="发送量" width=""></el-table-column>
            <el-table-column prop="ReceiveCount" label="领取量" width=""></el-table-column>
            <el-table-column prop="NewMemberCount" label="建档新客" width=""></el-table-column>
            <el-table-column prop="VerifyCount" label="核销量" width=""></el-table-column>
            <el-table-column label="领取率" width="">
                <template slot-scope="scoped">{{ scoped.row.ReceiveRatio }}%</template>
            </el-table-column>
            <el-table-column label="核销率" width="">
              <template slot-scope="scoped">{{ scoped.row.VerifyRatio }}%</template>
            </el-table-column>
            <el-table-column prop="Cost" label="成本" width=""></el-table-column>
            <el-table-column prop="Perfor" label="生产业绩" width=""></el-table-column>
            <el-table-column prop="ReceivePersonCount" label="领取人数" width=""></el-table-column>
            <el-table-column prop="VerifyPersonCount" label="使用人数" width=""></el-table-column>
            <el-table-column prop="NewMemberPersonCount" label="到店新客" width=""></el-table-column>
          </el-table>
        </div>
        <!-- end 自定义内容 -->
      </el-dialog>
    </div>
  </template>
  
  <script>
  import Coupon from "@/api/coupon";
  
  export default {
    data() {
      return {
        submitData: {
          // 提交数据
          begin_date: "",
          end_date: "",
          coupon_type: "",
        },
        multipleDate: [], // 日期选择器数据
        couponTypeDefine: [],   // 优惠券类型列表
        dataList: [], // 表格数据
        loading: false, // 表格加载
        showDialog: false, // 对话框显示
      };
    },
  
    mounted() {
      this.multipleDate = [
        this.util.getNowMonthToday(),
        this.util.getNowMonthToday(),
      ];
      this.onChangeDate();
    },
  
    methods: {
      // 初始化优惠券类型数据
      async initCouponStatis() {
        try {
          let { data } = await Coupon.initCouponStatis();
          this.couponTypeDefine = data.couponTypeDefine
        } catch (err) {
          console.log(err);
        }
      },
  
      // 获取员工发送数据列表
      async getCouponSelfStatis() {
        this.loading = true;
        try {
          let { data } = await Coupon.getCouponSelfStatis(this.submitData);
          this.dataList = data;
        } catch (err) {
          console.log(err);
        } finally {
          this.loading = false;
        }
      },

      // 自定义合计行函数
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        const values = data.map((item) => Number(item[column.property]));
        if (index === 0) {
          sums[index] = "合计";
          return;
        }else if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = "--";
        }
      });

      return sums;
    },
  
      // 时间更改事件
      onChangeDate() {
        this.submitData.begin_date =
          this.multipleDate.length > 0
            ? this.multipleDate[0]
            : this.util.getNowMonthToday();
        this.submitData.end_date =
          this.multipleDate.length > 0
            ? this.multipleDate[1]
            : this.util.getNowMonthToday();
      },
  
      // 打开对话框
      async onShowDialog() {
        await this.initCouponStatis();
        await this.getCouponSelfStatis()
        this.showDialog = true;
      },
  
      // 关闭对话框
      onCloseDialog() {
        this.showDialog = false;
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .empl-send-dialog {
    .content-box {
      height: 550px;
    }
  }
  </style>
  