<template>
  <div class="coupon">
    <div class="df-row">
      <div class="df-col">
        <!-- 券类型选择器 -->
        <el-select
          v-model="submitData.coupon_type"
          placeholder="请选择"
          size="mini"
        >
          <el-option
            v-for="item in couponTypeDefine"
            :key="item.Value"
            :label="item.Name"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <!-- 状态选择器 -->
        <el-select
          v-model="submitData.delete_mark"
          placeholder="请选择"
          size="mini"
        >
          <el-option
            v-for="item in deleteMarkDefine"
            :key="item.Value"
            :label="item.Name"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <!-- 单号文本 -->
        <el-input v-model="submitData.word" size="mini" placeholder="" />
      </div>

      <div class="df-col">
        <!-- 查询按钮 -->
        <el-button type="primary" size="mini" @click="onSearch">
          查询
        </el-button>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onShowAdd"
          >创建优惠券</el-button
        >
      </div>

      <div class="df-col">
        <!-- 员工发放 -->
        <el-button type="primary" size="mini" @click="onShowEmplSend"
          >员工发送情况</el-button
        >
      </div>

      <div class="df-col">
        <!-- 员工发放 -->
        <el-button type="primary" size="mini" @click="onShowCouponUse"
          >优惠券使用情况</el-button
        >
      </div>
    </div>

    <el-table
      v-loading="loading"
      :data="couponList"
      :max-height="tableMaxHeight"
      size="mini"
      border
      stripe
    >
      <el-table-column prop="CouponTypeName" label="类型" width="">
      </el-table-column>
      <el-table-column prop="CouponName" label="名称" width="">
      </el-table-column>
      <el-table-column prop="CouponNo" label="编号" width=""> </el-table-column>
      <el-table-column prop="ValidWeek" label="可用时段" width="300">
      </el-table-column>
      <el-table-column prop="UseNote" label="使用说明" width="300">
      </el-table-column>
      <el-table-column label="优惠幅度" width="">
        <template slot-scope="scoped">{{
          scoped.row.Preferential +
            (scoped.row.CouponType == 3
              ? "次"
              : scoped.row.CouponType == 2
              ? "%"
              : "元")
        }}</template>
      </el-table-column>
      <el-table-column prop="Price" label="兑换价格" width="">
      </el-table-column>
      <el-table-column
        prop="ValidDate"
        label="有效期"
        width=""
      ></el-table-column>
      <el-table-column prop="GiveOutCount" label="已发数量" width="">
      </el-table-column>
      <el-table-column prop="" label="操作" width="340">
        <template slot-scope="scoped">
          <el-button type="primary" size="mini" @click="onShowAdd(scoped.row)"
            >详情</el-button
          >
          <el-button size="mini" @click="onShowDetail(scoped.row)"
            >明细</el-button
          >
          <el-button
            type="success"
            size="mini"
            @click="onShowSendSetting(scoped.row)"
            >发放设置</el-button
          >
          <el-button
            :type="scoped.row.DeleteMark ? 'warning' : 'danger'"
            size="mini"
            :disabled="deleteDisabled"
            @click="updateCoupon(scoped.row)"
            >{{ scoped.row.DeleteMark ? "恢复" : "删除" }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- start 分页器 -->
    <el-pagination
      background
      :page-sizes="[10, 15, 20, 25]"
      :page-size="submitData.page_size"
      :current-page="submitData.page_index"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!--end 分页器-->

    <CouponBasicSetting
      ref="couponBasicSetting"
      :distribCoupon="submitData.distrib_coupon"
      @success="onSearch"
    ></CouponBasicSetting>

    <CouponSendSetting ref="couponSendSetting" @success=""></CouponSendSetting>

    <CouponDetailDialog ref="couponDetailDialog"></CouponDetailDialog>

    <EmplSendDialog ref="emplSendDialog"></EmplSendDialog>

    <CouponUseDialog ref="couponUseDialog"></CouponUseDialog>
  </div>
</template>

<script>
import Coupon from "@/api/coupon";

import CouponBasicSetting from "./components/coupon-basic-setting.vue";
import CouponSendSetting from "./components/coupon-send-setting.vue";
import CouponDetailDialog from "./components/coupon-detail-dialog.vue";
import EmplSendDialog from "./components/empl-send-dialog.vue";
import CouponUseDialog from "./components/coupon-use-dialog.vue";

export default {
  components: {
    CouponBasicSetting,
    CouponSendSetting,
    CouponDetailDialog,
    EmplSendDialog,
    CouponUseDialog,
  },

  data() {
    return {
      tableMaxHeight: "", // 表格最大高度
      totalRecord: 0, // 总数
      submitData: {
        distrib_coupon: 0, // 数据过滤 0 优惠券营销 1 分享返利
        word: "", // 关键字
        coupon_type: 0, // 优惠券类型
        delete_mark: 0, // 优惠券状态
        page_size: 20, // 页数
        page_index: 1, // 页码
      },
      couponTypeDefine: [], // 优惠券类型列表
      deleteMarkDefine: [], // 优惠券查询状态列表
      couponList: [], // 优惠券数据列表
      loading: false, // 表格加载
      deleteDisabled: false, // 删除防抖
    };
  },

  created() {
    this.getResize();
    // console.log(this.$route.name);
    this.submitData.distrib_coupon = this.$route.name == "Coupon" ? 0 : 1;
  },

  mounted() {
    window.addEventListener("resize", this.getResize);

    this.initCouponMaintList();
    this.getCouponMaintList();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 48);
    },

    // 初始化优惠券查询
    async initCouponMaintList() {
      this.loading = true;
      try {
        let { data } = await Coupon.initCouponMaintList();
        data.couponTypeDefine[0].Name = "全部";
        this.couponTypeDefine = data.couponTypeDefine;
        this.deleteMarkDefine = [
          { Name: "全部", Value: -1 },
          ...data.deleteMarkDefine,
        ];
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 获取优惠券数据列表
    async getCouponMaintList() {
      try {
        let { data } = await Coupon.getCouponMaintList(this.submitData);
        let { filter, list } = data;
        this.couponList = list;
        this.totalRecord = filter.TotalRecord;
      } catch (err) {
        console.log(err);
      }
    },

    // 删除、恢复优惠券
    updateCoupon(event) {
      let that = this;
      let text = event.DeleteMark ? "恢复" : "删除";
      this.$confirm(
        `确定${text}优惠券【${event.CouponNo}_${event.CouponName}】吗？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(async (res) => {
        that.deleteDisabled = true;
        try {
          let submitData = { coupon_guid: event.CouponGuid };
          let result;
          if (event.DeleteMark) {
            result = await Coupon.restoreCoupon(submitData);
          } else {
            result = await Coupon.deleteCoupon(submitData);
          }

          let { errcode } = result;

          if (errcode == 0) {
            that.$message.success(`${text}成功`);
            that.getCouponMaintList();
          }
        } catch (err) {
          console.log(err);
        } finally {
          that.deleteDisabled = false;
        }
      });
    },

    // 查询按钮点击事件
    onSearch() {
      this.submitData.page_index = 1;
      this.getCouponMaintList();
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getCouponMaintList();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getCouponMaintList();
    },

    // 打开新增\详情对话框
    onShowAdd(event) {
      this.$refs.couponBasicSetting.onShowDialog(event.CouponGuid);
    },

    // 打开发送设置对话框
    onShowSendSetting(event) {
      this.$refs.couponSendSetting.onShowDialog(event.CouponGuid);
    },

    // 打开优惠券明细对话框
    onShowDetail(event) {
      this.$refs.couponDetailDialog.onShowDialog(event.CouponGuid);
    },

    // 打开员工发放情况对话框
    onShowEmplSend() {
      this.$refs.emplSendDialog.onShowDialog();
    },

    // 打开优惠券使用情况
    onShowCouponUse() {
      this.$refs.couponUseDialog.onShowDialog();
    },

    // 打开发放设置对话框
    onShowSetting() {},
  },
};
</script>

<style lang="less" scoped>
.df-row {
  margin-top: 20px;
}

.el-pagination {
  float: right;
  margin-top: 20px;
}
</style>
