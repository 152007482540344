import { render, staticRenderFns } from "./empl-send-dialog.vue?vue&type=template&id=54d3dae0&scoped=true&"
import script from "./empl-send-dialog.vue?vue&type=script&lang=js&"
export * from "./empl-send-dialog.vue?vue&type=script&lang=js&"
import style0 from "./empl-send-dialog.vue?vue&type=style&index=0&id=54d3dae0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54d3dae0",
  null
  
)

export default component.exports