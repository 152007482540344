<template>
  <div class="coupon-send-setting">
    <el-dialog
      title="发放设置"
      :visible.sync="showDialog"
      width="40%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <!-- start 自定义内容 -->
      <div class="content-box">
        <div class="df-row">
          <div class="df-col">
            <!-- 职位选择器 -->
            <el-select
              v-model="submitData.DutyNo"
              placeholder="请选择"
              size="mini"
              @change="onChangeDuty"
            >
              <el-option
                v-for="item in dutyList"
                :key="item.DutyNo"
                :label="item.DutyName"
                :value="item.DutyNo"
              >
              </el-option>
            </el-select>
          </div>

          <div class="df-col">
            <!-- 级别选择器 -->
            <el-select
              v-model="submitData.LevelGuid"
              placeholder="请选择"
              size="mini"
            >
              <el-option
                v-for="item in levelList"
                :key="item.LevelGuid"
                :label="item.LevelName"
                :value="item.LevelGuid"
              >
              </el-option>
            </el-select>
          </div>

          <div class="df-col">
            <!-- 添加可发放职位按钮 -->
            <el-button type="primary" size="mini" @click="onShowAdd()"
              >添加可发放职位</el-button
            >
          </div>
        </div>

        <el-table
          v-loading="loading"
          :data="dataList"
          max-height="500"
          size="mini"
          border
          stripe
        >
          <el-table-column label="员工职位" width="">
            <template slot-scope="scoped">{{
              scoped.row.DutyName || "不限"
            }}</template>
          </el-table-column>
          <el-table-column label="职位级别" width="">
            <template slot-scope="scoped">{{
              scoped.row.LevelName || "不限"
            }}</template>
          </el-table-column>
          <el-table-column prop="Count" label="每天可发送数量" width="">
          </el-table-column>
          <el-table-column prop="" label="操作" width="">
            <template slot-scope="scoped">
              <el-button
                type="primary"
                size="mini"
                @click="onShowAdd(scoped.row)"
                >修改</el-button
              >
              <el-button
                type="danger"
                size="mini"
                :disabled="deleteDisabled"
                @click="deleteCouponCount(scoped.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- end 自定义内容 -->

      <!-- start 脚部 -->
      <span slot="footer" class="dialog-footer">
        <div class="btn-box">
          <el-button size="small" @click="onCloseDialog">取 消</el-button>
        </div>
      </span>
      <!-- end 脚部 -->
    </el-dialog>

    <CouponSendDutyAdd
      ref="couponSendDutyAdd"
      @success="getCouponCountList"
    ></CouponSendDutyAdd>
  </div>
</template>

<script>
import Coupon from "@/api/coupon";
import Employee from "@/api/employee";

import CouponSendDutyAdd from "./coupon-send-duty-add";

export default {
  components: { CouponSendDutyAdd },

  data() {
    return {
      submitData: {
        CouponGuid: "",
        DutyNo: "",
        LevelGuid: "",
      },
      dutyList: [], // 职位列表
      levelList: [], // 级别列表
      copyLevelList: [],
      dataList: [],
      loading: false,
      showDialog: false,
      submitDisabled: false, // 提交防抖
      deleteDisabled: false, // 删除防抖
    };
  },

  methods: {
    // 初始化职位级别数据
    async getCommonSelector() {
      try {
        let { data } = await Employee.getCommonSelector();
        this.dutyList = [{ DutyName: "不限", DutyNo: "" }, ...data.dutyList];
        this.levelList = [
          { DutyNo: "", LevelGuid: "", LevelName: "不限" },
          ...data.levelList,
        ];
        this.copyLevelList = JSON.parse(JSON.stringify(this.levelList));
        await this.$nextTick(() => {
          this.onChangeDuty();
        });
      } catch (err) {
        console.log(err);
      }
    },

    // 获取发送设置列表
    async getCouponCountList() {
      this.loading = true;
      try {
        let { data } = await Coupon.getCouponCountList({
          coupon_guid: this.submitData.CouponGuid,
          duty_no: this.submitData.DutyNo,
          level_guid: this.submitData.LevelGuid,
        });
        this.dataList = data;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 删除发送设置
    deleteCouponCount(event) {
      this.$confirm(`此删除将无法恢复，确定要删除吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async ()=>{
        try {
          let {errcode} = await Coupon.deleteCouponCount({
            coupon_count_guid: event.CouponCountGuid
          })
          if(errcode == 0){
            this.$message.success('删除成功')
            this.getCouponCountList()
          }
        } catch (err) {
          console.log(err);
        }
      })
    },

    // 打开添加、修改发放职位对话框
    onShowAdd(event = {}) {
      console.log(event);
      this.$refs.couponSendDutyAdd.onShowDialog(
        event,
        this.submitData.CouponGuid,
        this.dutyList,
        this.copyLevelList
      );
    },

    // 职位改变事件
    onChangeDuty() {
      let { levelList, dataInfo } = this.util.handleDutyLevel(
        this.submitData,
        this.copyLevelList
      );
      this.levelList = levelList;
      this.submitData = dataInfo;
    },

    // 打开对话框
    async onShowDialog(couponGuid) {
      this.submitData.CouponGuid = couponGuid;
      this.getCommonSelector();
      this.getCouponCountList();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.coupon-send-setting {
  .content-box {
    height: 500px;
  }
}
</style>
