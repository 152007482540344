<template>
  <div class="coupon-detail-dialog">
    <el-dialog
      title="优惠券明细"
      :visible.sync="showDialog"
      top="5%"
      width="80%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <!-- start 自定义内容 -->
      <div class="content-box">
        <div class="df-row">
          <el-form label-width="100px" size="mini">
            <el-row :gutter="rowSpan">
              <el-col :span="colSpan">
                <el-form-item label="优惠券类型">{{
                  couponInfo.CouponTypeName
                }}</el-form-item>
              </el-col>
              <el-col :span="colSpan">
                <el-form-item label="优惠券名称">{{
                  couponInfo.CouponName
                }}</el-form-item>
              </el-col>
              <el-col :span="colSpan">
                <el-form-item label="可用时段">{{
                  couponInfo.ValidWeek
                }}</el-form-item>
              </el-col>
              <el-col :span="colSpan">
                <el-form-item label="优惠幅度">{{
                  couponInfo.Preferential +
                    couponInfo.Preferential +
                    (couponInfo.CouponType == 3
                      ? "次"
                      : couponInfo.CouponType == 2
                      ? "%"
                      : "元")
                }}</el-form-item>
              </el-col>
              <el-col :span="colSpan">
                <el-form-item label="有效期">{{
                  couponInfo.ValidDate
                }}</el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="rowSpan">
              <el-col :span="colSpan">
                <el-form-item label="已发出">{{
                  statisData.SendCount
                }}</el-form-item>
              </el-col>
              <el-col :span="colSpan">
                <el-form-item label="已核销">{{
                  statisData.VerifyCount
                }}</el-form-item>
              </el-col>
              <el-col :span="colSpan">
                <el-form-item label="核销率">{{
                  statisData.VerifyRatio
                }}</el-form-item>
              </el-col>
              <el-col :span="colSpan">
                <el-form-item label="创造业绩">{{
                  statisData.RelatedPerfor
                }}</el-form-item>
              </el-col>
            </el-row>

            <el-row v-if="couponInfo.DistributionCoupon == 1" :gutter="rowSpan">
              <el-col :span="colSpan">
                <el-form-item label="分销类型">{{
                  couponInfo.CouponDistribType
                }}</el-form-item>
              </el-col>
              <el-col :span="colSpan">
                <el-form-item label="分销金额/积分">{{
                  couponInfo.Preferential
                }}</el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <div class="df-row">
          <div class="df-col">
            <!-- 员工选择器 -->
            <el-select
              v-model="submitData.sender_guid"
              placeholder="请选择"
              size="mini"
            >
              <el-option
                v-for="item in senderList"
                :key="item.Value"
                :label="item.Name"
                :value="item.Value"
              >
              </el-option>
            </el-select>
          </div>

          <div class="df-col">
            <!-- 状态选择器 -->
            <el-select
              v-model="submitData.state"
              placeholder="请选择"
              size="mini"
            >
              <el-option
                v-for="item in stateList"
                :key="item.Value"
                :label="item.Name"
                :value="item.Value"
              >
              </el-option>
            </el-select>
          </div>

          <div class="df-col">
            <!-- 关键字输入框 -->
            <el-input v-model="submitData.distrib_phone" size="mini"></el-input>
          </div>

          <div class="df-col">
            <!-- 查询按钮 -->
            <el-button type="primary" size="mini" @click="onSearch"
              >分销人电话查询</el-button
            >
          </div>
        </div>

        <el-table
          v-loading="loading"
          :data="dataList"
          max-height="500"
          size="mini"
          border
          stripe
        >
          <el-table-column label="发放时间" width="">
            <template slot-scope="scoped">{{
              util.dateFormat('YYYY-mm-dd HH:MM:SS', new Date(scoped.row.AddTime * 1000))
            }}</template>
          </el-table-column>
          <el-table-column prop="SenderName" label="发放员工" width=""></el-table-column>
          <el-table-column prop="CouponMemberNo" label="券码" width="">
          </el-table-column>
          <el-table-column prop="WXName" label="领取人" width=""></el-table-column>
          <el-table-column prop="WXPhone" label="领取人手机号" width=""></el-table-column>
          <el-table-column label="领取来源" width="">微信</el-table-column>
          <el-table-column label="状态" width="">
            <template slot-scope="scoped">{{ handleCouponState(scoped.row.CouponMemberState) }}</template>
          </el-table-column>
          <el-table-column prop="DistributionMemberName" label="分销人" width=""></el-table-column>
          <el-table-column label="操作" width="">
            <template slot-scope="scoped">
              <el-button
              v-if="scoped.row.CouponMemberState < 3"
                type="danger"
                size="mini"
                :disabled="deleteDisabled"
                @click="deleteCouponMember(scoped.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <!-- start 分页器 -->
        <el-pagination
          background
          :page-sizes="[10, 15, 20, 25]"
          :page-size="submitData.page_size"
          :current-page="submitData.page_index"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalRecord"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
        <!--end 分页器-->
      </div>
      <!-- end 自定义内容 -->

      <!-- start 脚部 -->
      <!-- <span slot="footer" class="dialog-footer">
        <div class="btn-box">
          <el-button size="small" @click="onCloseDialog">取 消</el-button>
        </div>
      </span> -->
      <!-- end 脚部 -->
    </el-dialog>
  </div>
</template>

<script>
import Coupon from "@/api/coupon";
import Employee from "@/api/employee";

export default {
  data() {
    return {
      rowSpan: 40,
      colSpan: 4,
      totalRecord: 0,
      submitData: {
        coupon_guid: "",
        sender_guid: "",
        state: 0,
        distrib_phone: "",
        page_index: 1,
        page_size: 10,
      },
      couponInfo: {},
      statisData: {},
      senderList: [],
      stateList: [],
      dataList: [],
      loading: false,
      showDialog: false,
      submitDisabled: false, // 提交防抖
      deleteDisabled: false, // 删除防抖
    };
  },

  methods: {
    // 初始化发放明细数据
    async initCouponMemberList() {
      try {
        let { data } = await Coupon.initCouponMemberList({
          coupon_guid: this.submitData.coupon_guid,
        });
        this.couponInfo = data.couponInfo;
        this.senderList = data.senderList;
        this.stateList = data.stateList;
        this.statisData = data.statisData;
      } catch (err) {
        console.log(err);
      }
    },

    // 获取发放明细列表
    async getCouponMemberList(){
        this.loading = true
        try {
            let { data } = await Coupon.getCouponMemberList(this.submitData)
            this.dataList = data.list
            this.totalRecord = data.filter.TotalRecord
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false
        }
    },

    // 删除已发放的优惠券
    deleteCouponMember(event){
      this.$confirm(
        `删除记录将无法恢复，确定要删除吗？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(async()=>{
        this.deleteDisabled = true
        try {
          let { errcode } = await Coupon.deleteCouponMember({
          coupon_member_guid: event.CouponMemberGuid
        })
        if(errcode == 0){
          this.$message.success('删除成功')
          this.getCouponMemberList()
        }
        } catch (err) {
          console.log(err);
        } finally {
          this.deleteDisabled = false
        }
       
      })
    },

    // 处理优惠券类型转换
    handleCouponState(couponMemberState){
        switch (couponMemberState) {
            case 4:
                return '删除'
                break;
            case 3:
                return '核销'
                break;
            case 2:
                return '领取'
                break;
            default:
                return '发放'
        }
    },

    // 查询按钮点击事件
    onSearch(){
        this.submitData.page_index = 1
        this.getCouponMemberList()
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getCouponMemberList()
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getCouponMemberList()
    },

    // 打开对话框
    async onShowDialog(couponGuid) {
      this.submitData.coupon_guid = couponGuid;
      this.initCouponMemberList();
      this.getCouponMemberList()
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.coupon-detail-dialog {
  .content-box {
    height: 700px;

    .df-row {
      .el-form {
        width: 100%;
      }
    }
  }
}

.el-pagination{
    margin-top: 20px;
    float: right;
}
</style>
