import { request } from "../utils/axios.js";

export default {
	// 营销 ===> 优惠券营销
	initCouponMaintList(params={}){
		return request('GET','/coupon/init_coupon_maint_list', params);
	},

    getCouponMaintList(params={}){
		return request('GET','/coupon/get_coupon_maint_list', params);
	},
    
    initSaveCoupon(params={}){
		return request('GET','/coupon/init_save_coupon', params);
	},

    saveCoupon(params={}){
		return request('POST','/coupon/save_coupon', params);
	},

	deleteCoupon(params={}){
		return request('GET','/coupon/delete_coupon', params);
	},

	restoreCoupon(params={}){
		return request('GET','/coupon/restore_coupon', params);
	},

    getCouponCountList(params={}){
		return request('GET','/coupon/get_coupon_count_list', params);
	},

    saveCouponCount(params={}){
		return request('POST','/coupon/save_coupon_count', params);
	},

    deleteCouponCount(params={}){
		return request('GET','/coupon/delete_coupon_count', params);
	},

	initCouponMemberList(params={}){
		return request('GET','/coupon/init_coupon_member_list', params);
	},

	getCouponMemberList(params={}){
		return request('GET','/coupon/get_coupon_member_list', params);
	},

	deleteCouponMember(params={}){
		return request('GET','/coupon/delete_coupon_member', params);
	},

	initCouponStatis(params={}){
		return request('GET','/coupon/init_coupon_statis', params);
	},

	getCouponSenderStatis(params={}){
		return request('GET','/coupon/get_coupon_sender_statis', params);
	},

	getCouponSelfStatis(params={}){
		return request('GET','/coupon/get_coupon_self_statis', params);
	},
}